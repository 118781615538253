
.footerWrapper{
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #B5E1D5;
  padding-top: 25px;
  margin: auto;
  @media (max-width: 475px) {
      padding: 10px;
  }
}

.image, .secondImage {
  height: 5vh;
  width: auto;
}
