#about {
  max-height: 300px;
  font-size: 1.4rem;
  display: flex;
  padding: 50px;
}

#safety article {
  display: grid;
  grid-gap: 1rem;
  width: 1vw;
  height: 1vh;
}

.SectionText {
    color: #00007E;
    padding-bottom: 1.5rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    text-align: justify;
    text-align: center;
    font-size: 3vw;

}

@media screen and (min-width: 1000px) {
  .SectionText {
    font-size: 22px;
  }
}

.SectionTitle {
    background: -webkit-linear-gradient(#B5E1D5, #76C5CF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5vw;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    padding: 20px;
}
