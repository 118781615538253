body {
  font-family: 'Helvetica';
  background: linear-gradient(0deg, #C6A4D4, #6467B1 80%);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App{
  display: flex;
  flex-direction: column;
}

.Hero {
  height: 700px;
  background-size: cover;
  background-position: center;

}

.HeroGroup {
  margin: 0 auto;
  max-width: 500px;
  padding: 150px 10px;
  text-align: center;
}

.HeroGroup h1 {
  background: -webkit-linear-gradient(#B5E1D5, #76C5CF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 100px;
}

@media screen and (max-width: 310px) {
  .HeroGroup h1 {
    font-size: 75px;
  }
}

@media screen and (max-width: 235px) {
  .HeroGroup h1 {
    font-size: 50px;
  }
}

.HeroGroup p {
  color: #FFFFFF;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 17px;
}

.HeroGroup a {
  display: inline-block;
  animation: HeroAnimation;
  animation-duration: 2s;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 17px;
  font-weight: 500;
  color: white;
  padding: 9px 20px;
  border-radius: 20px;
  text-decoration: none;
  background-color: #709DD3;
  margin: 25px;
}

.HeroGroup a:hover{
  background-color: #B5E1D5;
  transform: translateY(-7px);
  box-shadow: 0px 15px 20px rgba(181, 225, 213, 0.4);
}



@keyframes HeroAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
